/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;


@font-face {
    font-family: 'Calibre';
    font-weight: normal;
    src: url("../fonts/Calibre-Regular.woff2") format("woff2"), url("../fonts/Calibre-Regular.woff") format("woff"); }
    
@font-face {
    font-family: 'Calibre';
    font-weight: bold;
    src: url("../fonts/Calibre-Medium.woff2") format("woff2"), url("../fonts/Calibre-Medium.woff") format("woff"); }
    
@font-face {
    font-family: 'Calibre';
    font-weight: bold;
    font-style: italic;
    src: url("../fonts/Calibre-MediumItalic.woff2") format("woff2"), url("../fonts/Calibre-MediumItalic.woff") format("woff"); }
            
@font-face {
    font-family: 'Sneak';
    font-weight: normal;
    unicode-range: U+000-5FF;
    src: url("../fonts/Sneak-Medium.woff2") format("woff2"), url("../fonts/Sneak-Medium.woff") format("woff"); }

    
p {
    letter-spacing: 0.2px;
}


button#primary-menu-toggle {
    // transform: scale(1.3);
    span:first-child {
        transform: translateY(-0.375rem);
    }
    span:nth-child(3) {
        transform: translateY(0.375rem);
    }

    &.open {
        span:first-child {
            transform: rotate(45deg);
        }
        span:nth-child(2) {
            opacity: 0;
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}



section a {
    &:before {
        content: ' \2198\FE0E';
        font-weight: bold;
        font-size: 70%;
    padding-right: 0.2rem;
    text-decoration: none; 
    }
    // color: #E94F35; 
    &:hover {
        text-decoration: underline; 
    }
}
     
.coming_soon {
    position: absolute;
    top: -42%;
    width: 27%;
    left: 54%;
}
.img-tr {
    top: 69.3%;
    width: 26.7%;
    height: auto;
    left: 57.5%;
}
.img-sk {
    top: 26.3%;
    width: 29.7%;
    height: auto;
    left: 68.5%;
}
.img-hg {
    top: 41.4%;
    width: 40.2%;
    height: auto;
    left: 3.7%;
}
/*     
↘ 
↘  */



.event {
    padding-bottom: 1.2rem;

    // &.marked .date::before {
    //     content: '↘';
    //     color: #E94F35;
    // }

    // &::before {
    //     // "data:image/svg+xml; utf8, <svg height="10" width="10"></svg>"
    //     content: url('../img/cross.svg'); 
    //     display:block;
    //     height:20px;
    //     width:20px;
    // }

    background-image: url('../img/cross.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 10px 10px;
    

    .date {
        font-size: 2rem;
        display: block;
        // padding-bottom: .2rem;
    }
       
    
    span[itemprop="name"] {
        font-size: 1.3rem;
        line-height: 110%;
        display: block;
        padding-bottom: .2rem;
    }
    span[itemprop="location"] {
        display: block;
        padding-bottom: .3rem;

    }
    span[itemprop="description"] {
        font-size: 0.875rem;
        display: block;
        line-height: 115%;
        padding-bottom: .3rem;

    }
    .register {
        font-size: 0.875rem;
        display: block;
        line-height: 115%;

    }

}

.logos div {
    // background-image: url('../img/cross.svg');
    // background-repeat: no-repeat;
    // background-position: 100% 0;
    // background-size: 10px 10px;
    &::before {
            // "data:image/svg+xml; utf8, <svg height="10" width="10"></svg>"
            content: url('../img/cross.svg'); 
            display:block;
            position: relative;
            height: 10px;
            width: 10px;
            // left: -1rem;
            top: -2rem;
        }
    height: 3rem;
    padding-top: 1rem;
}

// i,em {
//     @apply text-green;
//     font-style: normal;
// }